import {
  Dialog,
  Box,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  FormControl,
  Button,
} from "@mui/material";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import { useEffect, useState } from "react";
import { AudioVideo } from "../models/StoreAggregate";
import { config } from "../../../authConfig";
import CloseIcon from "@mui/icons-material/Close";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import { useFormError } from "../../../Common/UI/hook/useFormError";

const clearForm = {
  location: "",
  televisions: {
    total: 0,
    bar: 0,
    diningRoom: 0,
    patio: 0,
  },
  ampModel: "",
  zoneControllerModel: "",
  audioZones: 0,
  musicDevicePlayerId: "",
  atmosphereTvDeviceSerialNumber: "",
  tvProvider: {
    name: "Astound/Grande",
    accountNumber: "",
  },
};

interface AVFormError {
  atmosphereTvDeviceSerialNumber: string;
  musicDevicePlayerId: string;
  TVProviderAccountNumber: string;
}

export default function AVDialog({
  show,
  setShow,
  initialFormData,
  location,
  fetchStoresInfo,
}: any) {
  const execute = useFetchMsal();
  const [formData, setFormData] = useState<AudioVideo>(initialFormData);
  const [updating, setUpdating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { formErrors, isSubmittable, handleInputBlur } =
    useFormError<AVFormError>({
      atmosphereTvDeviceSerialNumber: "",
      musicDevicePlayerId: "",
      TVProviderAccountNumber: "",
    });

  useEffect(() => {
    setFormData((prev) => {
      return initialFormData;
    });
  }, [initialFormData]);

  const handleClose = () => {
    setFormData(clearForm);

    // setFormErrors(initialFormErrors);

    setShow(false);
  };

  const handleEdit = () => {
    const payload = {
      ...formData,
      location: location,
    };

    const url = config.rootAPIList.editAV.replace("{location}", location);

    setUpdating(true);
    setError(null);

    execute("PUT", `${config.rootAPIUrl}${url}`, payload)
      .then(async () => {
        await fetchStoresInfo();
        setError(null);
      })
      .catch((error: any) => {
        setError("An error occurred while saving");
        console.log(error);
      })
      .finally(() => {
        setUpdating(false);
        handleClose();
      });
  };

  const validateFieldNotEmpty = (input: any) => {
    if (input.length === 0) {
      return "Field should not be empty";
    }
    return "";
  };

  return (
    <Dialog open={show} onClose={handleClose} aria-hidden={!show}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle>Edit AV</DialogTitle>
        <IconButton
          size="small"
          disableRipple
          sx={{
            "&:hover": {
              border: "1px solid black",
              borderRadius: "3px",
            },
            marginRight: "20px",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <LoadingIndicator show={updating}>
        <DialogContent>
          <Box className="card-container">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
              }}
            >
              <TextField
                type="number"
                required
                label="# Bar TVs"
                name="televisions.bar"
                value={formData.televisions.bar}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only integers
                  if (/^\d*$/.test(value)) {
                    setFormData((prev) => {
                      var newObj = { ...prev };
                      newObj.televisions.bar = parseInt(e.target.value);
                      return { ...newObj };
                    });
                  }
                }}
                inputProps={{ step: 1, min: 0 }}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === "e" || e.key === "-") {
                    e.preventDefault(); // Block decimal point, exponential, and negative keys
                  }
                }}
              />
              <TextField
                type="number"
                required
                label="# Dining Room TVs"
                name="diningRoomTVs"
                value={formData.televisions.diningRoom}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only integers
                  if (/^\d*$/.test(value)) {
                    setFormData((prev) => {
                      var newObj = { ...prev };
                      newObj.televisions.diningRoom = parseInt(e.target.value);
                      return { ...newObj };
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === "e" || e.key === "-") {
                    e.preventDefault(); // Block decimal point, exponential, and negative keys
                  }
                }}
                inputProps={{ step: 1, min: 0 }}
                // onBlur={(e) => handleInputBlur(e, validateWanAddress)}
              />
              <TextField
                type="number"
                required
                label="# Patio TVs"
                name="patioTVs"
                value={formData.televisions.patio}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only integers
                  if (/^\d*$/.test(value)) {
                    setFormData((prev) => {
                      var newObj = { ...prev };
                      newObj.televisions.patio = parseInt(e.target.value);
                      return { ...newObj };
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === "e" || e.key === "-") {
                    e.preventDefault(); // Block decimal point, exponential, and negative keys
                  }
                }}
                inputProps={{ step: 1, min: 0 }}
              />
              <TextField
                label="Amp Model"
                name="ampModel"
                // error={formErrors?.ampModel !== ""}
                // helperText={formErrors?.ampModel}
                value={formData?.ampModel}
                onChange={(e) => {
                  setFormData((prev) => {
                    var newObj = { ...prev };
                    newObj.ampModel = e.target.value;
                    return { ...newObj };
                  });
                }}
              />
              <TextField
                label="Zone Controller Model "
                name="zoneControllerModel"
                value={formData.zoneControllerModel}
                onChange={(e) => {
                  setFormData((prev) => {
                    var newObj = { ...prev };
                    newObj.zoneControllerModel = e.target.value;
                    return { ...newObj };
                  });
                }}
                inputProps={{ step: 1, min: 0 }}
              />
              <TextField
                type="number"
                required
                label="# of Audio Zones"
                name="audioZones"
                // error={formErrors?.numberOfAudioZones !== ""}
                // helperText={formErrors?.numberOfAudioZones}
                value={formData.audioZones}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only integers
                  if (/^\d*$/.test(value)) {
                    setFormData((prev) => {
                      var newObj = { ...prev };
                      newObj.audioZones = parseInt(e.target.value);
                      return { ...newObj };
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === "e" || e.key === "-") {
                    e.preventDefault(); // Block decimal point, exponential, and negative keys
                  }
                }}
                inputProps={{ step: 1, min: 0 }}
              />
              <TextField
                required
                label="Music Device Player ID"
                name="musicDevicePlayerId"
                error={!!formErrors.musicDevicePlayerId}
                helperText={formErrors.musicDevicePlayerId}
                value={formData?.musicDevicePlayerId}
                onChange={(e) => {
                  setFormData((prev) => {
                    var newObj = { ...prev };
                    newObj.musicDevicePlayerId = e.target.value;
                    return { ...newObj };
                  });
                }}
                onBlur={(e) => {
                  handleInputBlur(e, (input) => validateFieldNotEmpty(input));
                }}
              />

              <TextField
                required
                label="Atmosphere TV Device Serial # "
                name="atmosphereTvDeviceSerialNumber"
                value={formData?.atmosphereTvDeviceSerialNumber}
                error={!!formErrors.atmosphereTvDeviceSerialNumber}
                helperText={formErrors.atmosphereTvDeviceSerialNumber}
                onChange={(e) => {
                  setFormData((prev) => {
                    var newObj = { ...prev };
                    newObj.atmosphereTvDeviceSerialNumber = e.target.value;
                    return { ...newObj };
                  });
                }}
                onBlur={(e) => {
                  handleInputBlur(e, (input) => validateFieldNotEmpty(input));
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="TVProvider-label">TV Provider</InputLabel>
                <Select
                  labelId="TVProvider-label"
                  label="TV Provider"
                  name="tvProvider.name"
                  value={formData?.tvProvider?.name ?? ""}
                  onChange={(e) => {
                    setFormData((prev) => {
                      var newObj = { ...prev };
                      newObj.tvProvider.name = e.target.value;
                      return { ...newObj };
                    });
                  }}
                >
                  <MenuItem value={"Astound/Grande"}>Astound/Grande</MenuItem>
                  <MenuItem value={"DirecTV"}>DirecTV</MenuItem>
                  <MenuItem value={"Spectrum Business"}>
                    Spectrum Business
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                required
                label="Provider Account #"
                name="TVProviderAccountNumber"
                error={!!formErrors.TVProviderAccountNumber}
                helperText={formErrors.TVProviderAccountNumber}
                value={formData?.tvProvider?.accountNumber}
                onChange={(e) => {
                  setFormData((prev) => {
                    var newObj = { ...prev };
                    newObj.tvProvider.accountNumber = e.target.value;
                    return { ...newObj };
                  });
                }}
                onBlur={(e) => {
                  handleInputBlur(e, (input) => validateFieldNotEmpty(input));
                }}
              />
            </Box>
          </Box>
        </DialogContent>
      </LoadingIndicator>
      <Box
        sx={{
          color: "red",
          fontSize: "0.875rem",
          marginRight: "16px",
          flexGrow: 1,
          textAlign: "right",
        }}
      >
        {error ? error : ""}
      </Box>
      <DialogActions>
        {/* TODO: Change color of cancel button to black (secondary color) */}
        <Button sx={{ bgcolor: "secondary.main" }} onClick={handleClose}>
          Cancel
        </Button>
        {!updating ? (
          <Button
            onClick={handleEdit}
            disabled={
              updating ||
              !formData.musicDevicePlayerId.trim() ||
              !formData.atmosphereTvDeviceSerialNumber.trim() ||
              !formData.tvProvider.accountNumber.trim()
            }
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
}
