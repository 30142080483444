import { Typography } from "@mui/material";
import { theme } from '../../../Common/UI/styles/theme';

export default function ReleaseOnePointNine() {
  return (
    <>
      <Typography
        variant="h6"
        className="release-title"
        color={theme.palette.error.main}
      >
        RELEASE 2.2 - February 3rd, 2025 
      </Typography>
      <ul>
        <li>
        <b>NEW</b> Store Configuration
          <ul>
            <li style={{ listStyleType: "circle" }}>Introducing a new page on the Diablo Dashboard that will house Store related configuration information. Our first tab will be related to POS IP Scheme mapping for store POS devices. </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
        Dashboard Home – Latest Run Integration Status 
          <ul>
            <li style={{ listStyleType: "circle" }}>
            Added Bite Import Data into datalake 
            </li>
            <li style={{ listStyleType: "circle" }}>
            Employee Discount Card Details Uploaded to Paytronix 
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
        Product Catalog -
          <ul>
            <li style={{ listStyleType: "circle" }}>We added Product Description along with Allergens</li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
        Store 411 -
          <ul>
            <li style={{ listStyleType: "circle" }}><b>NEW</b> AV Tab - Now all Audio & Video information about a store is available under AV tab include total number of TVs in different dining areas along with Key information about Music Media Player, Atmosphere and TV Provider</li>
          </ul>
        </li>
      </ul>
    </>
  );
}
