export default function StoreConfig() {
  return (
    <>
      <p>
      This Page should house all Store Configuration information: </p>
     <a href="/storeConfiguration"> POS IP Scheme </a>
<p>This tab describes IP mapping for all devices in a store. This page can be edited only if the correct role is assigned </p>

    </>
  );
}
