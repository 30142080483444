import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import { theme } from "../../Common/UI/styles/theme";
import "./HelpContainer.css";

import { CardContainer } from "../../Common/UI/components/CardContainer";
import { GridTableHeader } from "../../Common/UI/components/GridTableHeader";
import { GridTableRow } from "../../Common/UI/components/GridTableRow";
import React from "react";
import { CustomTabPanel } from "../../Common/UI/components/CustomTabPanel";
import DashboardHomeHelp from "./DashboardHomeHelp";
import ReleasesHelp from "./Release/ReleasesHelp";
import ManagePosEmployeeHelp from "./ManagePosEmployeeHelp";
import IntegrationsHelp from "./IntegrationsHelp";
import ReportsHelp from "./ReportsHelp";
import Store411Help from "./Store411Help";
import { ProductCatalog } from "../../ProductCatalog/UI/ProductCatalog";
import DevDashboardHelp from "./DevDashboardHelp";
import ProductCatalogHelp from "./ProductCatalogHelp";
import StoreConfig from "./StoreConfig";

function HelpContainer(): ReactElement {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <CardContainer title="Help Center" className="help-center">
      <Box sx={{ width: "100%", minHeight: "calc(100vh - 250px)" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="Tabs help center"
          >
            <Tab
              label="Release Updates"
              className="help-tab"
              {...a11yProps(0)}
            />
            <Tab
              label="Dashboard Home"
              className="help-tab"
              {...a11yProps(1)}
            />
            <Tab
              label="Manage POS Emp"
              className="help-tab"
              {...a11yProps(2)}
            />
            <Tab label="Integrations" className="help-tab" {...a11yProps(3)} />
            <Tab label="Reports" className="help-tab" {...a11yProps(4)} />
            <Tab label="Store 411" className="help-tab" {...a11yProps(5)} />
            <Tab label="Product Catalog" className="help-tab" {...a11yProps(6)} />
            <Tab label="Dev Dashboard" className="help-tab" {...a11yProps(7)} />
            <Tab label="Store Configuration" className="help-tab" {...a11yProps(8)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabIndex} index={0}>
          <ReleasesHelp></ReleasesHelp>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <DashboardHomeHelp></DashboardHomeHelp>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <ManagePosEmployeeHelp></ManagePosEmployeeHelp>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={3}>
          <IntegrationsHelp />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={4}>
          <ReportsHelp />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={5}>
          <Store411Help />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={6}>
          <ProductCatalogHelp />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={7}>
          <DevDashboardHelp />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={8}>
          <StoreConfig />
        </CustomTabPanel>
      </Box>

      {/*<GridTableHeader>*/}
      {/*    <Grid item xs={12} >*/}
      {/*        <Typography variant="h1">Section 1</Typography>*/}
      {/*    </Grid>*/}
      {/*</GridTableHeader>*/}

      {/*<GridTableRow key={"key-section-1"} >*/}
      {/*    <Grid item xs={6} >*/}
      {/*        <Typography variant="h4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </Typography>*/}
      {/*    </Grid>*/}
      {/*</GridTableRow>*/}
    </CardContainer>
  );
}
export default HelpContainer;
